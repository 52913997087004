import React from 'react'
import { graphql, Link } from 'gatsby'
import { startCase, orderBy } from 'lodash'
import SEO from '../components/SEO'
import moment from 'moment'
import Layout from '../components/Layout'
import LBNavigation from '../components/LBNavigation'
import LBCard from '../components/LBCard'

const TagTemplate = ({ data, pageContext }) => {
  const posts = orderBy(
    data.contentfulTag.post,
    // eslint-disable-next-line
    [object => new moment(object.publishDateISO)],
    ['desc']
  )

  const { title } = data.contentfulTag
  const numberOfPosts = posts.length
  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = posts[0].heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <div className="bg-index-articoli">
      <LBNavigation isHome={false}/>
      <Layout>
        <SEO
          title={`Tag: ${startCase(title)}`}
          description={`Posts Tagged: ${startCase(title)}`}
          image={ogImage}
        />
          <div className="title-eyebrow eyebrow-articoli"><Link to="/">Home </Link> / <Link to="/articoli/lista">Articoli </Link> / {title}</div>
          <h1 className="title-tags">            
          { title }  <span className="title-articles-category-number"> ({numberOfPosts}) </span>
          </h1>
          <div className="articles-wrapper row no-gutters justify-content-center">
            { 
              posts.map((post, index) => (
               <LBCard key={"card" + post.id} post={post} page="articoli" articleNumber={index+1} basePath={basePath}/>
              ))
            }
          </div>
          <div className="tutti-gli-articoli-cta">
            <Link to="/articoli/lista" className="button-primary">tutti gli articoli</Link>
          </div>
      </Layout>
      <div className="articoli-bg"></div>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      title
      id
      slug
      post {
        id
        title
        slug
        publishDate(formatString: "DD MMMM YYYY", locale: "it")
        publishDateISO: publishDate(formatString: "YYYY-MM-DD")
        tags {
          id
          title
          slug
        }
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 160)
          }
        }
      }
    }
  }
`

export default TagTemplate
